'use strict';
import { TweenLite, CSSPlugin } from "gsap/all";
const plugins = [CSSPlugin];

export default class Modals {
    constructor() {
        this.name = 'Modals';

        const allModals = document.getElementsByClassName('modalBtn');
        const closeBtns = document.getElementsByClassName('modal__close');
        let bodyWidth = document.getElementsByTagName('body')[0].offsetWidth;


        this.onModalClose = (e) => {
            var thisItem = e.currentTarget.parentElement;

            if (e.currentTarget.dataset.modal) {
                thisItem = document.querySelector("#" + e.currentTarget.dataset.modal);
            }

            if (thisItem) {

                thisItem.classList.remove("open");
                thisItem.classList.add("close");
                TweenLite.to(thisItem, .25, { opacity: 0 });
                document.querySelector('body').classList.remove('modal-open');
            }
            e.preventDefault();
            return false;
        };


        this.onModalOpen = (e) => {
            var thisItem = e.currentTarget;

            if (thisItem) {
                var modal = document.getElementById(thisItem.dataset.modal);

                if (modal) {
                    modal.classList.remove("close");
                    modal.classList.add("open");

                    TweenLite.to(modal, .5, { opacity: 1 });

                    document.querySelector('body').classList.add('modal-open');
                }
            }

            e.preventDefault();
            return false;
        };


        if (allModals.length) {
            for (var i = 0; i < allModals.length; i++) {
                allModals[i].addEventListener('click', this.onModalOpen, true);
            }
        }

        if (closeBtns.length) {
            for (i = 0; i < closeBtns.length; i++) {
                closeBtns[i].addEventListener('click', this.onModalClose, true);
            }
        }

        this.destroy = () => {
            if (allModals.length) {
                for (var i = 0; i < allModals.length; i++) {
                    allModals[i].removeEventListener('click', this.onModalOpen, true);
                }
            }
            if (closeBtns.length) {
                for (i = 0; i < closeBtns.length; i++) {
                    closeBtns[i].removeEventListener('click', this.onModalClose, true);
                }
            }
        };
    }
}