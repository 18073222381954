'use strict';

import dust from '../components/DustField';
import { TweenLite, TweenMax, CSSPlugin, Linear, rotation } from 'gsap/all';


export default class HomePage {
    constructor() {
        this.name = 'Home Page';
        let isMobile = false;
        const slides = d.querySelectorAll(".home__slider__slide");
        let homeSlider = null;
        let pagingTimer = null;

        const isDescendant = function (parent, child) {
            var node = child.parentNode;
            while (node != null) {
                if (node == parent) {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        }

        const slidesResize = () => {


            for (var inner = 0; inner < slides.length; inner++) {
                const _h = getComputedStyle(slides[inner]).height;

                slides[inner].querySelector(".back_side_contents").style.height = _h;


            }


        };

        const addCustomPaging = () => {
            var container = d.querySelector('.mobile.home-pagination');
            var dots = container.querySelectorAll('.home-pagination-bullet');
            var width = parseInt(window.innerWidth * .9);


            for (var i = 0; i < dots.length; i++) {

                dots[i].addEventListener("click", (e) => {
                    console.log("dot click");
                    var index = parseInt(e.currentTarget.dataset.index);
                    var travelTo = (index * width) * -1;
                    homeSlider.translateTo(travelTo, 250, true, true);

                    e.preventDefault();
                    return false;
                });
            }

            dots[0].classList.add('home-pagination-bullet-active');

            homeSlider.on('slideChange', () => {
                for (var i = 0; i < slides.length; i++) {
                    slides[i].classList.remove("active");
                    slides[i].classList.remove("disabled");
                }
            });

            pagingTimer = window.setInterval(function () {

               // console.log("paging timer:: ")
                var width = parseInt(window.innerWidth * .8);
                var offsetl = Math.abs(homeSlider.translate);
                var style = window.getComputedStyle(d.querySelector('.swiper-wrapper'));
                var matrix = new WebKitCSSMatrix(style.transform);
        
                offsetl = Math.abs(matrix.m41);


                var closestValue = 9999;
                var closestIndex = 0;


                for (var i = 0; i < dots.length; i++) {

                    var range1 = width * i;
                    var range2 = width * (i + 1);

                    // console.log("Min:: ", range1, ":: Max ::", range2, " :: ", offsetl, (range1 <= offsetl && range2 < offsetl ));
                    dots[i].classList.remove('home-pagination-bullet-active');
                    //console.log('translateX: ', Math.abs(range1 - offsetl));



                    if (Math.abs(range1 - offsetl) < closestValue) {
                        closestValue = Math.abs(range1 - offsetl);
                        closestIndex = i;
                    }
                    //  console.log("closestValue:: ", closestValue);

                    //if (range1 <= offsetl && range2 > offsetl) {

                    //    console.log("adding this one");
                    //    dots[i].classList.add('home-pagination-bullet-active');
                    //} 

                }



                dots[closestIndex].classList.add('home-pagination-bullet-active');


            }, 1500);
        }



        const destroySlider = () => {
            homeSlider.destroy(true, true);

            if (pagingTimer != null) {
                window.clearInterval(pagingTimer);
            }
        }

        const setupSlider = () => {


            homeSlider = new Swiper('.home__slider .swiper-container', {
                slidesPerView: 'auto',
                spaceBetween: 0,
                cssMode: false,
                pagination: false
            });
            homeSlider.on('imagesReady', function () {
                for (var inner = 0; inner < slides.length; inner++) {
                    const _h = window.innerWidth * .88;
                    slides[inner].style.width = _h + "px";
                }

                slidesResize();
            });

            addCustomPaging();
        };



        const ppIcon = d.querySelector('.pp_icon_outer');
        const ppIconOuter = d.querySelector('.pp_icon_outer');

        if (ppIcon) {
            ppIcon.addEventListener("mouseover", (e) => {
                TweenLite.to(ppIconOuter, 20, { rotation: "360", ease: Linear.easeNone, repeat: -1 });
                e.preventDefault();
                return false;
            });


            ppIcon.addEventListener("mouseout", (e) => {



                var rotation = ppIconOuter.style.transform;
                var rotParts = rotation.split("rotate(");
                if (!rotParts || rotParts.length < 2) {
                    return;
                }
                var strAngle = rotParts[1].replace("deg)", "");
                var angle = parseInt(strAngle);

                var angleTo = "0";
                if (angle > 180) {
                    angleTo = "359";
                }


                TweenMax.killTweensOf(ppIconOuter);
                TweenLite.to(ppIconOuter, .5, {
                    rotation: angleTo, onComplete: () => {

                        TweenLite.set(ppIconOuter, { rotation: "0" });
                    }
                });

                e.preventDefault();
                return false;
            });

        }

        if (!d.querySelector('.homePage')) {
            return;
        }



        if (d.querySelector('#dust')) {

            var _canvas = d.querySelector('#dust');
            TweenLite.set(_canvas, { opacity: 0 });
            window.setTimeout(function () {
                var _field = new dust("#dust");

                TweenLite.to(_canvas, 2, { opacity: 1 });
            }, 1500);

        }

        const allDestiniBtns = d.querySelectorAll('.destiniModalBtn');

        for (var i = 0; i < allDestiniBtns.length; i++) {

            allDestiniBtns[i].addEventListener('click', (e) => {

                var destiniTag = e.currentTarget.dataset.destini;
                destini.init("destiniModal");
                destini.loadWidget(destiniTag);

                
            });
        }




        if (slides && slides.length) {
            for (var i = 0; i < slides.length; i++) {

                slides[i].addEventListener('mouseenter', (e) => {

                    for (var inner = 0; inner < slides.length; inner++) {
                        slides[inner].classList.remove("active");
                        slides[inner].classList.add("disabled");
                    }


                    e.currentTarget.classList.add('active');
                    e.currentTarget.classList.remove('disabled');


                    e.preventDefault();
                    return false;
                });


                slides[i].addEventListener('mouseleave', (e) => {


                    for (var inner = 0; inner < slides.length; inner++) {
         
                        slides[inner].classList.remove("disabled");
                    }

                    e.currentTarget.classList.remove('active');
                    e.currentTarget.classList.remove('disabled');

                    e.preventDefault();
                    return false;
                });


            }

            if (window.innerWidth < 768) {
                setupSlider();

                window.setTimeout(function () {
                    slidesResize();
                }, 1500);
            }

            //d.querySelector('body').addEventListener('click', (e) => {

            //    var _isOnEl = isDescendant(d.querySelector('.home__slider__container'), e.target);

            //    if (!_isOnEl) {
            //        for (var i = 0; i < slides.length; i++) {
            //            slides[i].classList.remove("active");
            //            slides[i].classList.remove("disabled");
            //        }
            //    }
            //    e.preventDefault();
            //    return false;
            //});





            window.addEventListener('resize', (e) => {
                slidesResize();


                if (parseInt(window.innerWidth) <= 768 && isMobile == false) {
                    // we switched to mobile
                    setupSlider();

                    window.setTimeout(function () {
                        slidesResize();
                    }, 1500);

                    d.querySelector('.home-pagination').style.display = "block";

                    isMobile = true;
                } else if (parseInt(window.innerWidth) > 768 && isMobile == true) {
                    // we switched to desktop
                    destroySlider();
                    d.querySelector('.home-pagination').style.display = "none";
                    isMobile = false;
                }
               
            });

            window.setTimeout(function () {
                slidesResize();
            }, 1500);



        }
    }
}