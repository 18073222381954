'use strict';
export default class Particle {
    constructor(ctx,W,H, mouse) {
        this.name = 'Particle';


        const randomNorm = (mean, stdev) => {

            return Math.abs(Math.round((Math.random() * 2 - 1) + (Math.random() * 2 - 1) + (Math.random() * 2 - 1)) * stdev) + mean;
        }


        this.h = parseInt(0);
        this.s = parseInt(10 );
        this.l = parseInt(80);
        this.a = 0.3 * Math.random();

       

        this.color = "hsla(" + this.h + "," + this.s + "%," + this.l + "%," + (this.a) + ")";
        this.shadowcolor = "hsla(" + this.h + "," + this.s + "%," + this.l + "%," + parseFloat(this.a - 0.55) + ")";
        this.x = Math.random() * W;
        this.y = Math.random() * H;

        //console.log("W :: ", W, " :: ", H);

        this.direction = {
            "x": -1 + Math.random() * 2,
            "y": -1 + Math.random() * 2
        };
        //this.radius = 9 * ((Math.random()*2-1)+(Math.random()*2-1)+(Math.random()*2-1)+3);
        this.radius = randomNorm(0, 4);
        this.scale = 0.8 * Math.random() + 0.5;
        this.rotation = Math.PI / 4 * Math.random();

        this.grad = ctx.createRadialGradient(this.x, this.y, this.radius, this.x, this.y, 0);
        this.grad.addColorStop(0, this.color);
        this.grad.addColorStop(1, this.shadowcolor);

        this.vx = (2 * Math.random() + 4) * .01 * this.radius;
        this.vy = (2 * Math.random() + 4) * .01 * this.radius;

        this.valpha = 0.01 * Math.random() - 0.02;

        this.move = function () {
            this.x += this.vx * this.direction.x;
            this.y += this.vy * this.direction.y;


            //console.log("this.x :: ", this.x, " :: ", this.y);

            this.rotation += this.valpha;
            //this.radius*= Math.abs((this.valpha*0.01+1));

        };
        this.changeDirection = function (axis) {
            this.direction[axis] *= -1;
            this.valpha *= -1;
        };
        this.draw = function () {
            ctx.save();
            ctx.translate(this.x + mouse.rx / -5 * this.radius, this.y + mouse.ry / -5 * this.radius); 
            ctx.rotate(this.rotation);
            ctx.scale(1, this.scale);

            this.grad = ctx.createRadialGradient(0, 0, this.radius, 0, 0, 0);
            this.grad.addColorStop(1, this.color);
            this.grad.addColorStop(0, this.shadowcolor);
            ctx.beginPath();
            ctx.fillStyle = this.grad;
            ctx.arc(0, 0, this.radius, 0, Math.PI * 2, false);
            ctx.fill();
            ctx.restore();


        };
        this.boundaryCheck = function () {



            if (this.x >= W * 1.2) {
                this.x = W * 1.2;
                this.changeDirection("x");
            } else if (this.x <= -W * 0.2) {
                this.x = -W * 0.2;
                this.changeDirection("x");
            }
            if (this.y >= H * 1.2) {
                this.y = H * 1.2;
                this.changeDirection("y");
            } else if (this.y <= -H * 0.2) {
                this.y = -H * 0.2;
                this.changeDirection("y");
            }
        };
    }
}