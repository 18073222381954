'use strict';
export default class Navigation {
    constructor() {
        this.name = 'Navigation';

        this.mobileBtn = d.querySelector('.hamburger');
        const that = this;

        this.openMenu = () => {
            d.querySelector('.body-content').classList.add('menu-open');
            d.querySelector('#mobileNav').classList.add('open');
            d.querySelector('body').classList.add('menu-open');
        }
        
        this.closeMenu = () => {
            d.querySelector('.body-content').classList.remove('menu-open');
            d.querySelector('#mobileNav').classList.remove('open');
            d.querySelector('body').classList.remove('menu-open');
        }


        this.mobileBtn.addEventListener('click', (e) => {

            if (e.currentTarget.classList.contains("active")) {
                e.currentTarget.classList.remove("active");
                that.closeMenu();
            } else {
                e.currentTarget.classList.add("active");
                that.openMenu();
            }

            e.preventDefault();
            return false;
        });


       const mobileLinks =  d.querySelectorAll('.topMobileNav a');
        for (let i = 0; i < mobileLinks.length; i++) {
            mobileLinks[i].setAttribute("style", "--animation-order: " + (i + 1));
        }

    }
}