'use strict';
import { TweenMax, CSSPlugin, ScrollToPlugin, gsap } from 'gsap/all';


export default class TextArrow {
    constructor() {

        const targets = document.querySelectorAll('.cta__text');
   
        if (targets && targets.length) {

            for (var i = 0; i < targets.length; i++) {
                targets[i].addEventListener('mouseover', (e) => {

                    const arrowCircle = e.currentTarget.querySelector('.svgArrowGroup');
                    const arrowHead = e.currentTarget.querySelector('.svgArrowHead');
                    const arrowTail = e.currentTarget.querySelector('.svgArrowLine');
                   
                    TweenMax.to(arrowCircle, .25, {y: 1, x: 9});
                    TweenMax.to(arrowTail, .25, { y: 10, x: -27, scaleX: 1.8});
                    TweenMax.to(arrowHead, .25, { x: 10});

                });

                targets[i].addEventListener('mouseleave', (e) => {

                    const arrowCircle = e.currentTarget.querySelector('.svgArrowGroup');
                    const arrowHead = e.currentTarget.querySelector('.svgArrowHead');
                    const arrowTail = e.currentTarget.querySelector('.svgArrowLine');

                    TweenMax.to(arrowCircle, .25, { y: 1, x: 1 });
                    TweenMax.to(arrowTail, .25, { y: 10, x: 1, scaleX: 1 });
                    TweenMax.to(arrowHead, .25, { x: 20 });

                });
            }
        }

    }
}

















