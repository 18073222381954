'use strict';
export default class Polyfills {

	constructor() {
		this.name = 'Polyfills';

		// polyfills
		this.objectIncludes();
		this.addAssignIndex();
		this.objectAssign();
		this.es6Remove();
		this.eventPolyfill();
	}

	eventPolyfill() {
		if (typeof window.CustomEvent === "function") return false;
		function CustomEvent(event, params) {
			params = params || { bubbles: false, cancelable: false, detail: undefined };
			var evt = document.createEvent('CustomEvent');
			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
			return evt;
		}
		CustomEvent.prototype = window.Event.prototype;
		window.CustomEvent = CustomEvent;
    }

	es6Remove() {
		if (!('remove' in Element.prototype)) {
			Element.prototype.remove = function () {
				if (this.parentNode) {
					this.parentNode.removeChild(this);
				}
			};
		}

	}


	addAssignIndex() {
		if (!Array.prototype.findIndex) {
			Object.defineProperty(Array.prototype, 'findIndex', {
				value: function (predicate) {
					// 1. Let O be ? ToObject(this value).
					if (this == null) {
						throw new TypeError('"this" is null or not defined');
					}

					var o = Object(this);

					// 2. Let len be ? ToLength(? Get(O, "length")).
					var len = o.length >>> 0;

					// 3. If IsCallable(predicate) is false, throw a TypeError exception.
					if (typeof predicate !== 'function') {
						throw new TypeError('predicate must be a function');
					}

					// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
					var thisArg = arguments[1];

					// 5. Let k be 0.
					var k = 0;

					// 6. Repeat, while k < len
					while (k < len) {
						// a. Let Pk be ! ToString(k).
						// b. Let kValue be ? Get(O, Pk).
						// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
						// d. If testResult is true, return k.
						var kValue = o[k];
						if (predicate.call(thisArg, kValue, k, o)) {
							return k;
						}
						// e. Increase k by 1.
						k++;
					}

					// 7. Return -1.
					return -1;
				}
			});
		}
	}

	objectIncludes() {
		if (!Array.prototype.includes) {
			Object.defineProperty(Array.prototype, 'includes', {
				value: function (searchElement, fromIndex) {

					if (this == null) {
						throw new TypeError('"this" is null or not defined');
					}

					// 1. Let O be ? ToObject(this value).
					var o = Object(this);

					// 2. Let len be ? ToLength(? Get(O, "length")).
					var len = o.length >>> 0;

					// 3. If len is 0, return false.
					if (len === 0) {
						return false;
					}

					// 4. Let n be ? ToInteger(fromIndex).
					//    (If fromIndex is undefined, this step produces the value 0.)
					var n = fromIndex | 0;

					// 5. If n ≥ 0, then
					//  a. Let k be n.
					// 6. Else n < 0,
					//  a. Let k be len + n.
					//  b. If k < 0, let k be 0.
					var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

					function sameValueZero(x, y) {
						return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
					}

					// 7. Repeat, while k < len
					while (k < len) {
						// a. Let elementK be the result of ? Get(O, ! ToString(k)).
						// b. If SameValueZero(searchElement, elementK) is true, return true.
						if (sameValueZero(o[k], searchElement)) {
							return true;
						}
						// c. Increase k by 1. 
						k++;
					}

					// 8. Return false
					return false;
				}
			});
		}
	}

	objectAssign() {
		if (typeof Object.assign != 'function') {
			Object.assign = function (target) {
				'use strict';
				if (target == null) {
					throw new TypeError('Cannot convert undefined or null to object');
				}

				target = Object(target);
				for (var index = 1; index < arguments.length; index++) {
					var source = arguments[index];
					if (source != null) {
						for (var key in source) {
							if (Object.prototype.hasOwnProperty.call(source, key)) {
								target[key] = source[key];
							}
						}
					}
				}
				return target;
			};
		}
	}
}