'use strict';

export default class Analytics {
    constructor() {
        this.name = 'Analytics';
        const that = this;

        /*FRAMEWORK LEVEL LINKS */
        const navLinks = d.querySelectorAll('nav a');
        const footerLinks = d.querySelectorAll('footer a');

        for (var i = 0; i < navLinks.length; i++) {
            navLinks[i].addEventListener('click', (e) => {
                that.addEventToDataLayer('header navigation', 'click', e.currentTarget.innerText);
            });
        }
        


        for (var i = 0; i < footerLinks.length; i++) {
            footerLinks[i].addEventListener('click', (e) => {
                that.addEventToDataLayer('footer navigation', 'click', e.currentTarget.innerText);
            });
        }


        const eLinks = d.querySelectorAll(".ga-link");

        for (var i = 0; i < eLinks.length; i++) {
            
        

            eLinks[i].addEventListener('click', (e) => {
                var cat = e.currentTarget.dataset.category;
                var action = e.currentTarget.dataset.action;
                var label = e.currentTarget.dataset.label;
                if (cat && action && label) {
                    that.addEventToDataLayer(cat, action, label);
                }
            });
        }



        window.dataLayer = window.dataLayer || [];
        const dataLayer = window.dataLayer;
        this.addEventToDataLayer = function (eCategory, eAction, eLabel) {
         console.log("eCategory:: ", eCategory, " :: eAction :: ", eAction, " :: eLabel :: ", eLabel);
            dataLayer.push({
                event: 'GAEvent', // always the same
                eventCategory: eCategory,  //column G
                eventAction: eAction, // column H
                eventLabel: eLabel // column I
            });
        }
    }
}


