'use strict';
import axios from 'axios';

export default class CookieManager {
    constructor() {
        this.name = 'Cookie Management';
        const that = this;


        //if (document.cookie.indexOf('visitor') < 0) {
        //    document.getElementById("CookieMessage").style.display = "block";
        //}


        this.SetShortCookie = (c_name, value, exdays) => {
            var exdate = new Date();
            exdate.setMinutes(exdate.getMinutes() + exdays);
            var c_value = escape(value) + (exdays === null ? "" : "; expires=" + exdate.toUTCString());
            document.cookie = c_name + "=" + c_value;
        };


        this.SetCookie = (c_name, value, exdays) => {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + exdays);
            var c_value = escape(value) + (exdays === null ? "" : "; expires=" + exdate.toUTCString());
            document.cookie = c_name + "=" + c_value;
        };

        this.GetCookie = (name) => {
            var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return v ? v[2] : null;
        };

        this.DismissCookieMessage = () => {
            that.SetCookie("visitor", "true", 3650)
            document.getElementById("CookieMessage").style.cssText = "top: -250px;";
        };


        this.CheckToShowCookie = () => {

        };

    }
}