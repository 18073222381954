import './../css/main.less';
import 'promise-polyfill/src/polyfill';
import "core-js/stable";
import "regenerator-runtime/runtime";
import PolyFills from './utils/Polyfills';
import CookieManager from './utils/CookieManager';
import Navigation from './core/Navigation';

import TextArrow from './components/TextArrow';
import Modals from './components/Modals';
import JumpButton from './components/JumpButton';

import HomePage from './pages/home';
import ProductPage from './pages/product';
import MissionPage from './pages/mission';

import Analytics from './utils/Analytics';

window.d = document;

(function () {
    if (typeof window.CustomEvent === "function") return false;
    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})();

document.addEventListener("DOMContentLoaded", () => {




    const polyfills = new PolyFills();
    const nav = new Navigation();
    const homePage = new HomePage();
    const productPage = new ProductPage();
    const missionPage = new MissionPage();
    //const ourStoryPage = new OurStoryPage();

    const modals = new Modals();
    const jumpButton = new JumpButton();
    const textArrow = new TextArrow();
    const analytics = new Analytics();

    window.CookieManager = new CookieManager();
    window.Analytics = analytics;
});
