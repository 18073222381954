'use strict';

import { TweenLite, ScrollToPlugin, TweenMax, CSSPlugin, Linear, rotation } from 'gsap/all';


export default class Mission {
    constructor() {
        this.name = 'Mission Page';
        let isMobile = false;
        let homeSlider = null;
        let pagingTimer = null;

        const offset = (el) => {
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        }



        /*INTERSECTION OBSERVER FOR STICKY NAV*/


        const allBlocks = document.querySelectorAll(".missionTabs .card-block");

        if (allBlocks.length == 0) {
            return false;
        }

      /*  document.querySelector("html").style.overflowX = "hidden";*/

        for (var i = 0; i < allBlocks.length; i++) {
            allBlocks[i].addEventListener("click", (e) => {
                const target = `#${e.currentTarget.dataset.target}`;
                const targetDiv = document.querySelector(target);

                var position = offset(targetDiv);
               // console.log("position:: ", position);

                if (e.currentTarget.dataset.target == "missionFromPeasToPackaging") {
                    position.top -= 70;
                }

                //gsap.to(window, {duration: 2, scrollTo: {y: 400, x: 250}});
                TweenLite.to(window, { duration: .75, scrollTo: { y: position.top, x: 0 } });
            });
        }

        const allliinks = d.querySelectorAll('.anim-footer');

        allliinks.forEach((link) => {
            link.addEventListener("click", (e) => {
                var position = offset(d.querySelector(".sources"));
                position.top -= 70;
                TweenLite.to(window, { duration: .75, scrollTo: { y: position.top, x: 0 } });

                e.preventDefault();
            });
        });
        //sources

        window.addEventListener("scroll", (e) => {
            const missionTabs = document.querySelector(".missionTabs.pinned");
            const notpinned = document.querySelector(".missionTabs.notpinned");
            const plantingWithPurpose = document.querySelector("#missionPlantingWithPurpose");
            const productWithPurpose = document.querySelector("#missionFromPeasToPackaging");
            const partneringWithPurpose = document.querySelector("#missionPartneringWithPurpose");
            const timelineCanvas = document.querySelector("#canvas");
            const threshold = parseInt(window.innerHeight) * .25;

            if (parseInt(notpinned.getBoundingClientRect().top) + parseInt(notpinned.getBoundingClientRect().height)  <= 0) {
                missionTabs.classList.remove("hidden");
                notpinned.classList.add("hidden");
            } else {
                missionTabs.classList.add("hidden");
                notpinned.classList.remove("hidden");
            }

            let activeId = "";

            if (parseInt(plantingWithPurpose.getBoundingClientRect().top) > -60 && parseInt(plantingWithPurpose.getBoundingClientRect().top) <= threshold) {
                activeId = plantingWithPurpose.getAttribute("id");
            } else if (parseInt(productWithPurpose.getBoundingClientRect().top) > -60 && parseInt(productWithPurpose.getBoundingClientRect().top) <= threshold) {
                activeId = productWithPurpose.getAttribute("id");
            } else if (parseInt(partneringWithPurpose.getBoundingClientRect().top) > -60 && parseInt(partneringWithPurpose.getBoundingClientRect().top) <= threshold) {
                activeId = partneringWithPurpose.getAttribute("id");
            }

            if (exportRoot && parseInt(timelineCanvas.getBoundingClientRect().top) <= threshold && !timelineCanvas.classList.contains("initted")) {
                exportRoot.step1.gotoAndPlay("animation_one");
                timelineCanvas.classList.add("initted")
            }

            // console.log("parseInt(plantingWithPurpose.getBoundingClientRect().top) :: ", parseInt(plantingWithPurpose.getBoundingClientRect().top), "::", threshold);
            if (activeId.length) {
                const target = document.querySelector(".missionTabs.pinned div[data-target=\"" + activeId + "\"]");

                if (target) {
                    if (document.querySelector(".missionTabs.pinned .card-block.active") && document.querySelector(".missionTabs.pinned .card-block.active").dataset.target != activeId) {
                        document.querySelector(".missionTabs.pinned .card-block.active").classList.remove("active");
                    }

                    target.classList.add("active");
                }
            }
        });

        let lastEvent = "value__selector one";
        let lastEventPackaging = "value__selector one";

        document.querySelectorAll("#missionFromPeasToPackaging .anim_nav .values a").forEach((input) => {

            input.addEventListener("mouseout", (e) => {
                document.querySelector("#missionFromPeasToPackaging .value__selector").setAttribute("class", lastEventPackaging);
            });

            input.addEventListener("mouseover", (e) => {
                lastEventPackaging = document.querySelector("#missionFromPeasToPackaging .value__selector").getAttribute("class");
                document.querySelector("#missionFromPeasToPackaging .value__selector").classList.remove("two");
                document.querySelector("#missionFromPeasToPackaging .value__selector").classList.remove("three");
                document.querySelector("#missionFromPeasToPackaging .value__selector").classList.add("one");

                switch (e.currentTarget.dataset.target) {
                    case "packaging_txt_1":
                        document.querySelector("#missionFromPeasToPackaging .value__selector").classList.add("one");
                        break;
                    case "packaging_txt_2":
                        document.querySelector("#missionFromPeasToPackaging .value__selector").classList.add("two");
                        break;
                    case "packaging_txt_3":
                        document.querySelector("#missionFromPeasToPackaging .value__selector").classList.add("three");
                        break;
                }
            });

            input.addEventListener("click", (e) => {
                if (e.currentTarget.classList.contains("active")) {
                    e.preventDefault();
                    return false;
                }

                const activeOne = document.querySelector("#missionFromPeasToPackaging .anim_nav a.active");
                if (activeOne) {
                    activeOne.classList.remove("active");
                }
                e.currentTarget.classList.add("active");
                lastEventPackaging = document.querySelector("#missionFromPeasToPackaging .value__selector").getAttribute("class");

                document.querySelectorAll("#missionFromPeasToPackaging .valueTex.active").forEach((item) => {

                    TweenLite.to(item, .5, {
                        alpha: 0, onComplete: () => {
                            item.classList.remove("active");
                        }
                    })
                });
                const newTarget = document.querySelector("#" + e.currentTarget.dataset.target);
                window.setTimeout(function () {
                    newTarget.classList.add("active");
                    TweenLite.set(newTarget, { alpha: 0 });
                    TweenLite.to(newTarget, .5, { alpha: 1 });
                }, 1000);

                e.preventDefault();
                return false;
            });


        });

        document.querySelectorAll("#missionPlantingWithPurpose .anim_nav .values a").forEach((input) => {

            input.addEventListener("mouseout", (e) => {
                document.querySelector(".value__selector").setAttribute("class", lastEvent);
            });

            input.addEventListener("mouseover", (e) => {
                lastEvent = document.querySelector("#missionPlantingWithPurpose .value__selector").getAttribute("class");
                document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("two");
                document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("three");
                document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("one");

                switch (e.currentTarget.dataset.target) {
                    case "planting_txt_1":
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("one");
                        break;
                    case "planting_txt_2":
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("two");
                        break;
                    case "planting_txt_3":
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("three");
                        break;
                }
            });

            input.addEventListener("click", (e) => {

                if (e.currentTarget.classList.contains("active")) {
                    e.preventDefault();
                    return false;
                }

                const activeOne = document.querySelector("#missionPlantingWithPurpose .anim_nav a.active");
                if (activeOne) {
                    activeOne.classList.remove("active");
                }

                exportRoot.step1.gotoAndStop(0);
                exportRoot.step2.gotoAndStop(0);
                exportRoot.step3.gotoAndStop(0);
                e.currentTarget.classList.add("active");
                lastEvent = document.querySelector("#missionPlantingWithPurpose .value__selector").getAttribute("class");

                document.querySelectorAll("#missionPlantingWithPurpose .valueTex.active").forEach((item) => {

                    TweenLite.to(item, .5, {
                        alpha: 0, onComplete: () => {
                            item.classList.remove("active");
                        }
                    })
                });
                const newTarget = document.querySelector("#" + e.currentTarget.dataset.target);
                window.setTimeout(function () {

                    newTarget.classList.add("active");
                    TweenLite.set(newTarget, { alpha: 0 });
                    TweenLite.to(newTarget, .5, { alpha: 1 });
                }, 1000);


                switch (e.currentTarget.dataset.target) {
                    case "planting_txt_1":
                        exportRoot.step1.gotoAndPlay("animation_one");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("two");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("three");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("one");
                        break;
                    case "planting_txt_2":
                        exportRoot.step2.gotoAndPlay("animation_two");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("two");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("three");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("one");
                        break;
                    case "planting_txt_3":
                        exportRoot.step3.gotoAndPlay("animation_three");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("two");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.add("three");
                        document.querySelector("#missionPlantingWithPurpose .value__selector").classList.remove("one");
                        break;

                }
                e.preventDefault();
                return false;
            });

        });





        //const resizeHero = () => {
        //    const frame = document.querySelector(".pictureFrame");
        //    frame.style.width = window.innerWidth;
        //    frame.style.height = getComputedStyle(document.querySelector("#missionHero")).height;
        //    console.log("resizing:: ");
        //}
        //window.resizeHero = resizeHero;

        //window.addEventListener("resize", (e) => {

        //    resizeHero();
        //});

        //const el = document.querySelector("#missionTabs");
        //const observer = new IntersectionObserver(callback, { threshold: [0, 0.25, 0.5, 0.75, 1] });

        //observer.observe(el);
    }
}