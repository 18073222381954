'use strict';
import { TweenLite, CSSPlugin, ScrollToPlugin, gsap  } from 'gsap/all';


export default class JumpButton {
    constructor() {


        const upArrow = d.querySelector('#jumpUp');

        if (upArrow) {

            
            gsap.registerPlugin(ScrollToPlugin);

            window.addEventListener('scroll', (e) => {

                var h = parseInt(window.outerHeight);
                var loc = parseInt(window.scrollY);


                if (loc == NaN || !loc) {
                    loc = document.documentElement.scrollTop;
                }
                //console.log("loc::", loc, "::h::", h);
                if (loc > h) {
                    upArrow.style.display = "block";
                } else {
                    upArrow.style.display = "";
                }

                e.preventDefault();
            });

            upArrow.addEventListener('click', (e) => {
                TweenLite.to(window, 1, { scrollTo: { x: 0, y: 0, autoKill: false } });

                e.preventDefault();
                return false;
            });
        }
    }
}












