'use strict';

import dust from '../components/DustField';
import Modals from '../components/Modals';
import { TweenLite, CSSPlugin } from 'gsap/all';
import Axios from "axios";
import Stickyfill from 'stickyfilljs';

export default class ProductPage {
    constructor() {
        this.name = 'Product Page';

        if (!d.querySelector('.product__detail')) {
            return;
        }

        const mobileNav = d.querySelector('#flavorNav .mobileFlavor');
        const product = d.querySelector('.hero__content__home__prod');
        let sizeMenu = d.querySelector('.mobile_sizeselector');
        let modalFramework = null;

        let mobileNavSwiper = null;

        const slideToActiveFlavor = () => {

          
            var activeLink = d.querySelector('.mobileFlavor a.active');
          
            var _containerWidth = parseInt(getComputedStyle(d.querySelector('.mobileFlavor')).width);
            var offset = activeLink.offsetLeft - _containerWidth + activeLink.offsetWidth;


            if (d.querySelectorAll('.mobileFlavor a').length < 3) {
                return;
            }

            if (offset > 0) {
                mobileNavSwiper.translateTo(- 1 * offset, 250, false, false);
            } else {
                mobileNavSwiper.translateTo(- 1 * activeLink.offsetLeft, 250, false, false);
            }
        };

        const transitionFlavor = (newPage, prodObj) => {
            //JUST SWAP OUT
            var oldFlavorNav = d.querySelector('#flavorNav');
            var newFlavorNav = newPage.querySelector('#flavorNav');
           
            var oldPageContent = d.querySelector('#pageContent');

            var newPS = newPage.querySelector('.plant__stats');
            var oldPS = d.querySelector('.plant__stats');

            oldPS.outerHTML = newPS.outerHTML;

            //oldPageContent.querySelector('.hero').classList.add('loading');
            
            //window.setTimeout( function () {
            //    d.querySelector('.hero').classList.remove('loading');
            //}, 500);

            var newBG = "";
            var oldBG = "";
            var oldSlug = ""

            for (var i = 0; i < oldPageContent.classList.length; i++) {
                if (oldPageContent.classList[i] != "sticky") {
                    oldSlug = oldPageContent.classList[i];
                }
            }
            for (var i = 0; i < oldFlavorNav.classList.length; i++) {
                if (oldFlavorNav.classList[i] != "sticky") {
                    oldBG = oldFlavorNav.classList[i];
                }
            }
            for (var i = 0; i < newFlavorNav.classList.length; i++) {
                if (newFlavorNav.classList[i] != "sticky") {
                    newBG = newFlavorNav.classList[i];
                }
            }


            var oldBGList = d.querySelectorAll("." + oldBG);
            var oldSlugList = d.querySelectorAll("." + oldSlug);

            for (var i = 0; i < oldBGList.length; i++) {
                oldBGList[i].classList.remove(oldBG);
                oldBGList[i].classList.add(newBG);
            }


            for (var i = 0; i < oldSlugList.length; i++) {
                oldSlugList[i].classList.remove(oldSlug);
                oldSlugList[i].classList.add(prodObj.flavor);
            }


            //AREAS TO TRANSITION - NOT SUPER EASY
            const oldRightArea = d.querySelector('.hero__content__home__right');
            const newRightArea = newPage.querySelector('.hero__content__home__right');


            const oldBottomArea = d.querySelector('.mobile__description');
            const newBottomArea = newPage.querySelector('.mobile__description');

            let oldBGImg = d.querySelector('.hero__bg');
            let newBGImg = newPage.querySelector('.hero__bg');


            oldBGImg.classList.add('old__bg');
            newBGImg.classList.add('transition__bg');
            newBGImg.style.opacity = 0;

            d.querySelector('.hero').appendChild(newBGImg);



            TweenLite.to(newBGImg, .75, {
                opacity: 1, delay: .5, onComplete: () => {

                    try {

                        d.querySelector('.hero').removeChild(d.querySelector('.old__bg'));
                    } catch (e) { }

                    newBGImg.classList.remove('transition__bg');
                }
            })



            let oldBuyMobile = d.querySelector('.buyMobile');
            let newBuyMobile = newPage.querySelector('.buyMobile');
            TweenLite.to(oldBuyMobile, .25, {
                opacity: 0, onComplete: () => {

                    oldBuyMobile.innerHTML = newBuyMobile.innerHTML;

                    TweenLite.to(oldBuyMobile, .5, { opacity: 1, delay: .5 });
                }
            })

            oldFlavorNav.setAttribute('class', newFlavorNav.getAttribute('class'));
            oldFlavorNav.setAttribute('class', newFlavorNav.getAttribute('class'));
            oldFlavorNav.classList.add("is-pinned");


            let el = document.querySelector("#flavorNav");

            let observer = new IntersectionObserver(
                ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
                { threshold: [1] }
            );
            observer.observe(el);

            for (var i = 0; i < oldFlavorNav.querySelectorAll('.desktopFlavor a').length; i++) {
                var linkUrl = oldFlavorNav.querySelectorAll('.desktopFlavor a')[i].getAttribute('href');
                if (linkUrl.indexOf(prodObj.flavor) >= 0) {
                    oldFlavorNav.querySelectorAll('.desktopFlavor a')[i].classList.add('active');
                } else {
                    oldFlavorNav.querySelectorAll('.desktopFlavor a')[i].classList.remove('active');
                }

            }


            for (var i = 0; i < oldFlavorNav.querySelectorAll('.mobileFlavor a').length; i++) {
                var linkUrl = oldFlavorNav.querySelectorAll('.mobileFlavor a')[i].getAttribute('href');

                if (linkUrl.indexOf(prodObj.flavor) >= 0) {
                    oldFlavorNav.querySelectorAll('.mobileFlavor a')[i].classList.add('active');
                } else {
                    oldFlavorNav.querySelectorAll('.mobileFlavor a')[i].classList.remove('active');
                }
            }

            TweenLite.to(oldRightArea, .5, {
                opacity: 0,
                onComplete: () => {
                    oldRightArea.innerHTML = newRightArea.innerHTML;
                    oldRightArea.style.opacity = 0;
                    TweenLite.to(oldRightArea, .5, { delay: 1, opacity: 1 });


                    const oldSizes = d.querySelectorAll('.size__selector a');
                    let sizeActiveBar = d.querySelectorAll('.activeBar');
                    let newLocation = 0;

                    for (var i = 0; i < oldSizes.length; i++) {
                        oldSizes[i].classList.remove("active");

                        if (oldSizes[i].getAttribute('href').indexOf(prodObj.size) >= 0) {
                            newLocation = oldSizes[i].offsetLeft;
                            oldSizes[i].classList.add("active");


                        }
                    }
                    TweenLite.to(sizeActiveBar, 0, { left: newLocation + "px" });
                    slideToActiveFlavor();






                }
            });

            TweenLite.to(oldBottomArea.querySelector('.container'), .5, {
                opacity: 0,
                onComplete: () => {
                    oldBottomArea.innerHTML = newBottomArea.innerHTML;
                    oldBottomArea.querySelector('.container').style.opacity = 0;
                    TweenLite.to(oldBottomArea.querySelector('.container'), .5, { opacity: 1 });
                }
            });



            transitionSize(newPage, prodObj);

            TweenLite.delayedCall(.75, () => {

                setupLinks(true);

                modalFramework = new Modals();

                //var destiniTag = document.querySelector('.destiniModalBtn').dataset.destini;
                //destini.init("destiniModal");
                //destini.loadWidget(destiniTag);
                d.querySelectorAll(".destiniModal").forEach((item) => {
                    item.addEventListener("click", (e) => {
                        destini.init(e.currentTarget.dataset.destinicontainer);
                        destini.loadWidget("", e.currentTarget.dataset.apo);
                    });
                });


                const alleLinks = d.querySelectorAll(".ga-link");
                const eLinks = d.querySelectorAll(".hero .ga-link");
                const footerLinks = d.querySelectorAll(".product__related__row .ga-link");
                const baseBtn = d.querySelector('.hero .destiniModal');
                const analyticsCat = baseBtn.dataset.category;


                for (var i = 0; i < alleLinks.length; i++) {
                    alleLinks[i].dataset.category = analyticsCat;
                }

                for (var i = 0; i < eLinks.length; i++) {

                    eLinks[i].addEventListener('click', (e) => {
                        var cat = e.currentTarget.dataset.category;
                        var action = e.currentTarget.dataset.action;
                        var label = e.currentTarget.dataset.label;
                        if (cat && action && label) {
                            Analytics.addEventToDataLayer(cat, action, label);
                        }
                    });
                }

              //  console.log("footer Links:: ", footerLinks);
                for (var i = 0; i < footerLinks.length; i++) {

                    footerLinks[i].addEventListener('click', (e) => {
                        var cat = e.currentTarget.dataset.category;
                        var action = e.currentTarget.dataset.action;
                        var label = e.currentTarget.dataset.label;
                        if (cat && action && label) {
                            Analytics.addEventToDataLayer(cat, action, label);
                        }
                    });
                }
            });
        };


        const onPushPopState = (e) => {
            const state = e.state;
            console.log("state :: ", state);
            if (!state || !state.page_id) {
                return;
            }

            loadFlavor(state.page_id);

        };

        const transitionSize = (newPage, prodObj) => {
            //AREAS JUST TO SWAP OUT - SUPER EASY 
            const newRelated = newPage.querySelector('.product__related');
            const newLabel = newPage.querySelector('#NutritionContent');
            d.querySelector('.product__related').innerHTML = newRelated.innerHTML;

            var newFooter = newPage.querySelector('.home__nationalpark');
            var oldFooter = d.querySelector('.home__nationalpark');
            oldFooter.outerHTML = newFooter.outerHTML;

            let relatedLinks = d.querySelectorAll('.product__related__row a');
            for (var i = 0; i < relatedLinks.length; i++) {

                relatedLinks[i].addEventListener('click', (e) => {
                    if (e.currentTarget.classList.contains('active')) {
                        e.preventDefault();
                        return false;
                    }

                    loadFlavor(e.currentTarget.getAttribute('href'));


                    e.preventDefault();
                });
            }
            if (newLabel && d.querySelector('#NutritionContent')) {
                d.querySelector('#NutritionContent').innerHTML = newLabel.innerHTML;
                PepNutritionLabel.init();
            }

            const oldBuyMain = d.querySelector('.desktopHero .destiniModal');
            const oldBuyNav = d.querySelector('#flavorNav .destiniModalBtn');
            oldBuyMain.outerHTML = newPage.querySelector('.desktopHero .destiniModal').outerHTML;
            oldBuyNav.outerHTML = newPage.querySelector('#flavorNav .destiniModalBtn').outerHTML;

            //AREAS TO TRANSITION - NOT SUPER EASY

            // transition main image
            var oldProd = d.querySelector('.hero__content__home__prod');
            var newProd = newPage.querySelector('.hero__content__home__prod');
            TweenLite.to(oldProd, .5, {
                opacity: 0,
                onComplete: () => {
                    oldProd.innerHTML = newProd.innerHTML;
                    oldProd.style.opacity = 0;
                    TweenLite.to(oldProd, .5, { delay: .5, opacity: 1 });
                }
            });


            var oldDP = d.querySelector('.size__dropdown');
            var newDP = newPage.querySelector('.size__dropdown');


            if (oldDP) {
                TweenLite.to(oldDP, .5, {
                    opacity: 0,
                    onComplete: () => {
                        if (oldDP && newDP) {
                            oldDP.innerHTML = newDP.innerHTML;
                            oldDP.style.opacity = 0;
                            TweenLite.to(oldDP, .5, { opacity: 1 });
                        }

                        sizeMenu = d.querySelector('.mobile_sizeselector');
                        if (sizeMenu) {
                            var mobileLinks = sizeMenu.querySelectorAll('a');
                            for (var i = 0; i < mobileLinks.length; i++) {
                                mobileLinks[i].addEventListener('click', (e) => {
                                    loadSize(e.currentTarget.getAttribute('href'));
                                    e.preventDefault();
                                });
                            }
                        }

                    }
                });
            }

            //slide size selector around
            const oldSizes = d.querySelectorAll('.size__selector a');
            let sizeActiveBar = d.querySelectorAll('.activeBar');
            let newLocation = 0;

            for (var i = 0; i < oldSizes.length; i++) {
                oldSizes[i].classList.remove("active");

                if (oldSizes[i].getAttribute('href').indexOf(prodObj.size) >= 0) {
                    newLocation = oldSizes[i].offsetLeft;
                    oldSizes[i].classList.add("active");


                }
            }

            if (sizeActiveBar) {
                TweenLite.to(sizeActiveBar, .5, { left: newLocation + "px" });
            }

            modalFramework = new Modals();

            //var destiniTag = document.querySelector('.destiniModalBtn').dataset.destini;
            //destini.init("destiniModal");
            //destini.loadWidget(destiniTag);

            d.querySelectorAll(".destiniModal").forEach((item) => {
                item.addEventListener("click", (e) => {
                    destini.init(e.currentTarget.dataset.destinicontainer);
                    destini.loadWidget("", e.currentTarget.dataset.apo);
                });
            });

            // console.log("active left:: ", newLocation, " :: size :: ", prodObj.size)


        };

        const loadFlavor = (url) => {

            var fuse = 0;
            const height = parseInt(getComputedStyle(d.querySelector('header')).height);
            if (parseInt(window.scrollY) != height) {
                fuse = 1;
            }

            if (Math.abs(parseInt(window.scrollY) - height) < 400) {

                fuse = .5;
            } else if (Math.abs(parseInt(window.scrollY) - height) > 2000) {
                fuse = 1.5;
            }


            TweenLite.to(window, fuse, {
                scrollTo: { x: 0, y: height }, onComplete: () => {
                    Axios.get(url + "?ajax=true").then(function (response) {

                        TweenLite.delayedCall(.5, function () {

                            var prodObj = {};

                            var productSection = url.split('product/');
                            var urlParts = productSection[1].split('/');
                            prodObj.category = urlParts[0];
                            prodObj.flavor = urlParts[1];
                            prodObj.size = urlParts[2];

                            let newPage = document.createRange().createContextualFragment(response.data);
                            window.history.pushState({ "page_id": url, "pageTitle": newPage.querySelector("title").innerHTML }, "", url);
                            transitionFlavor(newPage, prodObj);
                        })

                    });
                }
            })



        };

        const loadSize = (url) => {

            Axios.get(url + "?ajax=true").then(function (response) {


                var prodObj = {};

                var productSection = url.split('product/');
                var urlParts = productSection[1].split('/');
                prodObj.category = urlParts[0];
                prodObj.flavor = urlParts[1];
                prodObj.size = urlParts[2];


                let newPage = document.createRange().createContextualFragment(response.data);
                window.history.pushState({ "page_id": url, "pageTitle": newPage.querySelector("title").innerHTML }, "", url);
                transitionSize(newPage, prodObj);
            });
        };


        const setupLinks = (sizeOnly) => {

            const flavorNavLinks = d.querySelectorAll('#flavorNav a');
            const sizeNavLinks = d.querySelectorAll('.size__selector a');


            for (var i = 0; i < sizeNavLinks.length; i++) {

                sizeNavLinks[i].addEventListener('click', (e) => {
                    if (e.currentTarget.classList.contains('active')) {
                        e.preventDefault();
                        return false;
                    }

                    loadSize(e.currentTarget.getAttribute('href'));
                    e.preventDefault();
                });
            }
            sizeMenu = d.querySelector('.mobile_sizeselector');


            if (sizeMenu) {
                sizeMenu.addEventListener('click', (e) => {
                    const menuH = parseInt(getComputedStyle(d.querySelector('.size__dropdown')).height) + 20;
                    const mask = d.querySelector('.size__dropdown__container');

                    if (mask.classList.contains("open")) {
                        TweenLite.to(mask, .25, { height: "30px" });
                        mask.classList.remove("open");
                    } else {
                        mask.classList.add("open");
                        TweenLite.to(mask, .25, { height: menuH + "px" });
                    }
                });

                var mobileLinks = sizeMenu.querySelectorAll('a');
                for (var i = 0; i < mobileLinks.length; i++) {
                    mobileLinks[i].addEventListener('click', (e) => {
                        loadSize(e.currentTarget.getAttribute('href'));
                        e.preventDefault();
                    });
                }
            }


            if (!sizeOnly) {
                for (var i = 0; i < flavorNavLinks.length; i++) {

                    flavorNavLinks[i].addEventListener('click', (e) => {
                        if (e.currentTarget.classList.contains('active')) {
                            e.preventDefault();
                            return false;
                        }

                        loadFlavor(e.currentTarget.getAttribute('href'));


                        e.preventDefault();
                    });
                }
            }
        };


        if (d.querySelectorAll('#flavorNav a').length) {
            window.history.pushState({ "page_id": window.location.href, "pageTitle": document.querySelector("title").innerHTML }, "", window.location.href);

            window.addEventListener('popstate', (e) => {
                onPushPopState(e);

            });
            window.addEventListener('pushstate', (e) => {
                onPushPopState(e);
            });
            setupLinks();

            let relatedLinks = d.querySelectorAll('.product__related__row a');
            for (var i = 0; i < relatedLinks.length; i++) {

                relatedLinks[i].addEventListener('click', (e) => {
                    if (e.currentTarget.classList.contains('active')) {
                        e.preventDefault();
                        return false;
                    }

                    loadFlavor(e.currentTarget.getAttribute('href'));


                    e.preventDefault();
                });
            }


            const oldSizes = d.querySelectorAll('.size__selector a');
            let sizeActiveBar = d.querySelectorAll('.activeBar');
            let newLocation = 0;

            var prodObj = {};
            var url = window.location.href;
            var productSection = url.split('product/');
            var urlParts = productSection[1].split('/');


            for (var i = 0; i < oldSizes.length; i++) {
                oldSizes[i].classList.remove("active");

                if (oldSizes[i].getAttribute('href').indexOf(urlParts[2]) >= 0) {
                    newLocation = oldSizes[i].offsetLeft;
                    oldSizes[i].classList.add("active");
                }
            }
            TweenLite.to(sizeActiveBar, .25, { left: newLocation + "px" });

        }


        if (d.querySelector('#dust')) {

            var _canvas = d.querySelector('#dust');
            TweenLite.set(_canvas, { opacity: 0 });
            window.setTimeout(function () {
                var _field = new dust("#dust");

                TweenLite.to(_canvas, 2, { opacity: .5 });
            }, 1500);

        }

        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (isIE11) {
            var elements = document.querySelectorAll('.sticky');

            if (elements.length) {
                Stickyfill.add(elements);
            }

        }


        if (mobileNav) {
            mobileNavSwiper = new Swiper('#flavorNav .mobileFlavor', {
                // Optional parameters
                slidesPerView: 'auto',

                speed: 850,
                grabCursor: true,
                keyboard: {
                    enabled: true,
                },
                freeMode: true,
                freeModeSticky: false,
                freeModeMomentumRatio: .25,
                freeModeMomentumVelocityRatio: 1,
                autoHeight: false,
            })

            TweenLite.delayedCall(1.5, slideToActiveFlavor);
          
        }

        if (parseInt(window.innerWidth) > 768) {
            const el = document.querySelector("#flavorNav");

            const observer = new IntersectionObserver(
                ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
                { threshold: [1] }
            );
            observer.observe(el);
          
        }


        if (product) {
           // TweenLite.to(product, .5, { opacity: 1, delay: .5 });
        }

        if (d.querySelectorAll('#destiniModal').length) {
            //var destiniTag = document.querySelector('.destiniModalBtn').dataset.destini;
            //destini.init("destiniModal");
            //destini.loadWidget(destiniTag);

            console.log("loading destini");

            d.querySelectorAll(".destiniModal").forEach((item) => {
                item.addEventListener("click", (e) => {

                    console.log("destini open");
                    destini.init(e.currentTarget.dataset.destinicontainer);
                    destini.loadWidget("", e.currentTarget.dataset.apo);
                });
            });
        }

        if (d.querySelector('#NutritionModal')) {
            PepNutritionLabel.init();
        }



        if (parseInt(window.innerWidth) < 768) {
            const body = document.querySelector('body');
            const html = document.querySelector('html');

            let buyBtn = d.querySelector('.buyMobile');
            let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

            window.addEventListener('resize', (e) => {
                height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            });

            window.addEventListener('scroll', (e) => {
                var bottomTarget = height - window.outerHeight;
                var scrollPct = (window.scrollY / bottomTarget) * 100;

                if (scrollPct > 95) {
                    buyBtn.style.visibility = 'hidden';
                } else {
                    buyBtn.style.visibility = '';
                }

                e.preventDefault();
            });
        }




    }
}