'use strict';

import Particle from './Particle';

export default class DustField {
    constructor(target) {
        this.name = 'Dust Field';
        return;
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;


        if (isIE11) {
            return false;
        }

        if (parseInt(window.innerWidth) < 1024) {
            return false;
        }


        const canvas = d.querySelector(target);
        let ctx = null;
        const that = this;
        const particleCount = 400;
        let particles = [];


        const W = parseInt(getComputedStyle(d.querySelector('.hero')).width);
        const H = parseInt(getComputedStyle(d.querySelector('.hero')).height);



        canvas.width = W;
        canvas.height = H;

        ctx = canvas.getContext("2d"); 
        ctx.globalCompositeOperation = "lighter";

        var mouse = {
            x: 0,
            y: 0,
            rx: 0,
            ry: 0,
            speed: 0,
            delta: 0
        };
        document.addEventListener('mousemove', function (e) {

            mouse.x = e.clientX || e.pageX;
            mouse.y = e.clientY || e.pageY;
            mouse.x -= W / 2;
            mouse.y -= H / 2;

        }, false);

        const clearCanvas = () => {

            ctx.clearRect(0, 0, W, H);
        }

        const createParticles = () => {
            for (var i = particleCount - 1; i >= 0; i--) {
                var p = new Particle(ctx,W,H, mouse);
                particles.push(p);
            }
        } // end createParticles

        const drawParticles = () => {
            for (var i = particleCount - 1; i >= 0; i--) {
                var p = particles[i];
                p.draw();
            }


        } //end drawParticles

        const updateParticles = () => {
            for (var i = particles.length - 1; i >= 0; i--) {
               var p = particles[i];
                p.move();
                p.boundaryCheck();

            }
        } //end updateParticles

        const initParticleSystem = () => {
            createParticles();
            drawParticles();
        }

        const animateParticles= () => {
            clearCanvas();
            setDelta();
            drawParticles();
            updateParticles();
            requestAnimationFrame(animateParticles);
        }

        initParticleSystem();
        requestAnimationFrame(animateParticles);


        const setDelta = () => {
            this.now = (new Date()).getTime();
            //mouse.delta = (this.now - this.then) / 1000;
            this.then = this.now;
        }

    }

}